import * as React from "react"
import* as styles from '../styles/layout/footer.module.scss'
import { Link } from "gatsby"

const Footer = () => (
  <footer className={`${styles.footer} container top-container text-center`}>
    <ul>
      <li>
        <Link to="/privacy">プライバシーポリシー</Link>
      </li>
      <li>
        <Link to="/contact">お問い合わせ</Link>
      </li>
    </ul>
    <p className={styles.copyrights}>
      Copyright © DanRan Co.,Ltd. All Rights Reserved.
    </p>
  </footer>
)

export default Footer
