import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../../components/seo"
import AppLayout from "../../components/app/app-layout"
import {PrivacyContent} from "../privacy";

const Privacy = () => (
  <AppLayout>
    <Seo title="プライバシーポリシー" isIndex={false} pagePath={"/privacy"}/>

    <article className="container page-article-main privacy-page">
      <PrivacyContent/>
    </article>
  </AppLayout>
)

export default Privacy

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
