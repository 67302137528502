import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import* as styles from '../styles/layout/header.module.scss'

const Header = ({ siteTitle }) => {
  return (
    <header className={styles.header}>

      <div class={styles.logo}>
        <Link to="/">
            <StaticImage
              className="header-logo"
              src="../images/common/cp_header_logo@2x.png"
              alt="株式会社DanRan"
              width={278}
            />
          </Link>
      </div>

      <div>
        <input type="checkbox" id={styles.drawerCheck}/>

        <label for={styles.drawerCheck} className={styles.hamburgerMenu}>
          <span></span>
          <span></span>
          <span></span>
        </label>

        <nav className={styles.naviMenu}>
          <ul>
            <li><Link to="/">Top</Link></li>
            <li><Link to="/products">Service</Link></li>
            <li><Link to="/news">News</Link></li>
            <li><Link to="/blog">Blog</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
