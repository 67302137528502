import * as React from "react"
import Seo from "../components/seo"
import Layout from '../components/layout';
import {graphql} from "gatsby"
import {useTranslation} from "gatsby-plugin-react-i18next"

export const PrivacyContent = () => {
  const {t} = useTranslation();

  return (
    <>

      <h1>{t("privacy.title")}</h1>

      <section>
        <p>{t("privacy.lead.content.item1")}</p>
        <p>{t("privacy.lead.content.item2")}</p>
        <p>{t("privacy.lead.content.item3")}</p>
      </section>

      <section>
        <h2>{t("privacy.ch1.title")}</h2>
        <ol>
          <li>{t("privacy.ch1.content.item1")}</li>
          <li>{t("privacy.ch1.content.item2")}</li>
          <li>{t("privacy.ch1.content.item3")}</li>
        </ol>
      </section>

      <section>
        <h2>{t("privacy.ch2.title")}</h2>
        <ol>
          <li>
            {t("privacy.ch2.content.item1.lead")}
            <ol>
              <li dangerouslySetInnerHTML={{__html: t("privacy.ch2.content.item1.item1")}}/>
              <li dangerouslySetInnerHTML={{__html: t("privacy.ch2.content.item1.item2")}}/>
              <li dangerouslySetInnerHTML={{__html: t("privacy.ch2.content.item1.item3")}}/>
            </ol>
          </li>
          <li>{t("privacy.ch2.content.item2")}</li>
        </ol>
      </section>

      <section>
        <h2>{t("privacy.ch3.title")}</h2>
        <ol>
          <li>
            {t("privacy.ch3.content.item1.lead")}
            <ol>
              <li>{t("privacy.ch3.content.item1.item1")}</li>
              <li>{t("privacy.ch3.content.item1.item2")}</li>
              <li>{t("privacy.ch3.content.item1.item3")}</li>
              <li>{t("privacy.ch3.content.item1.item4")}</li>
              <li>{t("privacy.ch3.content.item1.item5")}</li>
              <li>{t("privacy.ch3.content.item1.item6")}</li>
              <li>{t("privacy.ch3.content.item1.item7")}</li>
              <li>{t("privacy.ch3.content.item1.item8")}</li>
              <li>{t("privacy.ch3.content.item1.item9")}</li>
              <li>{t("privacy.ch3.content.item1.item10")}</li>
              <li>{t("privacy.ch3.content.item1.item11")}</li>
            </ol>
          </li>

          <li>
            {t("privacy.ch3.content.item2.lead")}
            <ol>
              <li>{t("privacy.ch3.content.item2.item1")}
              </li>
              <li>{t("privacy.ch3.content.item2.item2")}</li>
              <li>{t("privacy.ch3.content.item2.item3")}</li>
              <li>{t("privacy.ch3.content.item2.item4")}</li>
            </ol>
          </li>
          <li>{t("privacy.ch3.content.item3")}</li>
        </ol>
      </section>

      <section>
        <h2>{t("privacy.ch4.title")}</h2>
        <ol>
          <li>
            {t("privacy.ch4.content.item1.lead")}
            <ol>
              <li>{t("privacy.ch4.content.item1.item1")}</li>
              <li>{t("privacy.ch4.content.item1.item2")}</li>
              <li>{t("privacy.ch4.content.item1.item3")}</li>
              <li>{t("privacy.ch4.content.item1.item4")}</li>
              <li>{t("privacy.ch4.content.item1.item5")}</li>
              <li>{t("privacy.ch4.content.item1.item6")}</li>
              <li>{t("privacy.ch4.content.item1.item7")}</li>
            </ol>
          </li>
        </ol>
      </section>

      <section>
        <h2>{t("privacy.ch5.title")}</h2>
        <p>{t("privacy.ch5.lead")}</p>
      </section>

      <section>
        <h2>{t("privacy.ch6.title")}</h2>
        <ol>
          <li>{t("privacy.ch6.content.item1")}</li>
          <li>{t("privacy.ch6.content.item2")}</li>
          <li>{t("privacy.ch6.content.item3")}</li>
          <li dangerouslySetInnerHTML={{__html: t("privacy.ch6.content.item4")}}/>
        </ol>
      </section>

      <section>
        <h2>{t("privacy.ch7.title")}</h2>
        <p dangerouslySetInnerHTML={{__html: t("privacy.ch7.lead")}}/>
        <ol>
          <li>{t("privacy.ch7.content.item1")}</li>
          <li>{t("privacy.ch7.content.item2")}</li>
          <li>{t("privacy.ch7.content.item3")}</li>
        </ol>
      </section>

      <section>
        <h2>{t("privacy.ch8.title")}</h2>
        <ol>
          <li>{t("privacy.ch8.content.item1")}</li>
          <li>{t("privacy.ch8.content.item2")}</li>
        </ol>
      </section>

      <section>
        <h2>{t("privacy.ch9.title")}</h2>
        <p>{t("privacy.ch9.lead")}</p>
      </section>

      <section>
        <h2>{t("privacy.ch10.title")}</h2>
        <p>{t("privacy.ch10.lead")}</p>

        <div className="privacy-page__contact">
          <p>
            <a href="https://docs.google.com/forms/d/1wbBFFBt0OE0qZakAbGmnTqKfttVbwoI98nux_Abf8zc" target="_blank"
               rel="noreferrer">
              {t("privacy.ch10.contactForm")}
            </a>
          </p>
        </div>
      </section>
      <footer className="privacy-page__footer">
        <p className="privacy-page__revision">{t("privacy.footer.published")}</p>
        <p className="privacy-page__revision">{t("privacy.footer.updated.item1")}</p>
        <p className="privacy-page__revision">{t("privacy.footer.updated.item2")}</p>
      </footer>
    </>
  )
    ;
}

const Privacy = () => {
  return (
    <Layout>
      <Seo title="プライバシーポリシー"
           description="当社は、当社が提供するサービス（以下（「本サービス」といいます。）におけるユーザー情報等の適切な取扱いの確保のため、以下のとおり、個人情報保護方針を定めます。"
           pagePath={"/privacy"}/>
      <article className="container article-container">
        <PrivacyContent/>
      </article>
    </Layout>
  );
}

export default Privacy

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
